<template>
  <section id="auditoria-seguimiento-molex">
    <!--Filters-->
    <auditoria-molex-filter
      @refetch-data="refetchData"
    />

    <!-- Hallazgos -->
    <auditoria-molex-eventos
      ref="refMolexEvents"
    />

    <chartjs-doughnut-chart
      ref="refChartTracking"
      :title="$t('dashboards.auditoriaMolex.molexTracking.follow-upFindings')"
    />

    <auditoria-molex-data-bar-hallazgos
      ref="refCharSegumientoPeriodos"
      :title="$t('dashboards.auditoriaMolex.molexTracking.findingsPending')"
      :subtitle="$t('dashboards.auditoriaMolex.molexTracking.perPeriod')"
    />

    <auditoria-molex-data-bar-hallazgos
      ref="refCharSegumientoAnio"
      :title="$t('dashboards.auditoriaMolex.molexTracking.findingsInProgress')"
      :subtitle="$t('dashboards.auditoriaMolex.molexTracking.perPeriod')"
    />
  </section>
</template>
<script>
import { ref, onMounted } from '@vue/composition-api'
import dashboardService from '@/services/dashboard.service'
import common from '@/libs/common'
import store from '@/store'
import ChartjsDoughnutChart from '@/views/dashboard/ChartjsDoughnutChart.vue'
import AuditoriaMolexFilter from './AuditoriaMolexFilter.vue'
import AuditoriaMolexDataBarHallazgos from './AuditoriaMolexDataBarHallazgos.vue'
import AuditoriaMolexEventos from './AuditoriaMolexEventos.vue'

export default {
  components: {
    AuditoriaMolexFilter,
    ChartjsDoughnutChart,
    AuditoriaMolexDataBarHallazgos,
    AuditoriaMolexEventos,
  },
  setup() {
    /* Refs */
    const refMolexEvents = ref(null)
    const refChartTracking = ref(null)
    const refCharSegumientoPeriodos = ref(null)
    const refCharSegumientoAnio = ref(null)
    /* Services */
    const { fetchAuditoriaMolexSeguimientoGraficas } = dashboardService()

    const { toastSuccess } = common()

    const refetchData = () => {
      refMolexEvents.value.refetchData()

      const nowDate = new Date()
      const year = nowDate.getFullYear()
      const dateFrom = `${year}-01-01`
      const dateTo = `${year}-12-31`

      fetchAuditoriaMolexSeguimientoGraficas({
        fechaDesde: store.state.auditoriaMolex.dateFrom,
        fechaHasta: store.state.auditoriaMolex.dateTo,
        aduana: store.state.auditoriaMolex.custom,
        clienteId: store.state.auditoriaMolex.customer,
      }, data => {
        refChartTracking.value.updateChar(data.seriesAgrupadasEtiquetas, data.seriesAgrupadasValores)
        refCharSegumientoPeriodos.value.updateChar(data.pendientesColumnaSeries, data.pendientesPeriodos, data.graficaPeriodoSumas, data.total)
      })
      fetchAuditoriaMolexSeguimientoGraficas({
        fechaDesde: dateFrom,
        fechaHasta: dateTo,
        aduana: store.state.auditoriaMolex.custom,
        clienteId: store.state.auditoriaMolex.customer,
      }, data => {
        refCharSegumientoAnio.value.updateChar(data.pendientesColumnaSeries, data.pendientesPeriodos, data.graficaPeriodoSumas, data.total)
      })
    }

    onMounted(() => { refetchData() })

    return {
      // Refs
      refMolexEvents,
      refChartTracking,
      refCharSegumientoPeriodos,
      refCharSegumientoAnio,

      refetchData,
      // UI
      toastSuccess,
    }
  },
}
</script>
<style lang="scss" scoped>

</style>
