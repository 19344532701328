<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and legend -->
      <b-card-title class="mb-50">
        {{ title }}
        <!-- Comparativa por periodo -->
      </b-card-title>
      <b-card-sub-title class="mb-2">
        {{ subtitle }}
      </b-card-sub-title>
      <!--/ title and legend -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        ref="columnChar"
        type="bar"
        height="400"
        :options="columnChart.chartOptions"
        :series="columnChart.series"
      />
      <app-collapse
        v-if="graficaPeriodoSumasData"
      >
        <app-collapse-item :title="$t('dashboards.auditoriaMolex.dataBarHallazgos.details')">
          <b-table
            :items="graficaPeriodoSumasData"
            responsive
            :fields="graficaPeriodoSumasFields"
            :foot-clone="true"
            class="mb-0"
          >
            <template #foot(nombre)>
              {{ $t('dashboards.auditoriaMolex.dataBarHallazgos.highTotal') }}
            </template>
            <template #foot(total)>
              {{ graficaPeriodoSumasTotal }}
            </template>
          </b-table>
        </app-collapse-item>
      </app-collapse>
    </b-card-body>
  </b-card>
</template>

<script>
import i18n from '@/libs/i18n'
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BTable,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    BCardTitle,
    BCardSubTitle,
    BTable,

    AppCollapse,
    AppCollapseItem,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      graficaPeriodoSumasData: [],
      graficaPeriodoSumasFields: [
        { key: 'nombre', label: i18n.t('dashboards.auditoriaMolex.dataBarHallazgos.periodLabel') },
        { key: 'total', label: i18n.t('dashboards.auditoriaMolex.dataBarHallazgos.totalLabel') },
      ],
      graficaPeriodoSumasTotal: 0,
      columnChart: {
        series: [],
        chartOptions: {
          chart: {
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          colors: [
            '#FFB56E',
            '#826BF8',
            '#00D4BD',
            '#2B9BF4',
            '#EA5455',
          ],
          plotOptions: {
            bar: {
              columnWidth: '80%',
              colors: {
                backgroundBarColors: [
                  '#F3F2F7',
                  '#F3F2F7',
                  '#F3F2F7',
                  '#F3F2F7',
                  '#F3F2F7',
                  '#F3F2F7',
                ],
                backgroundBarRadius: 10,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left',
          },
          stroke: {
            show: true,
            colors: ['transparent'],
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
          },
          fill: {
            opacity: 1,
          },
        },
      },
    }
  },
  methods: {
    updateChar(series, periodos, graficaPeriodoSumas, total) {
      /*eslint-disable */ 
      this.columnChart.chartOptions = {
        ...this.columnChart.chartOptions, ...
        {
          xaxis: {
          categories: periodos
        }
      }}
      this.$refs.columnChar.updateSeries(series)
      this.graficaPeriodoSumasData = graficaPeriodoSumas
      this.graficaPeriodoSumasTotal = total
    },
  },
}
</script>
