<script>
import { Doughnut } from 'vue-chartjs'
import { $themeColors } from '@themeConfig'

const chartColors = {
  primaryColorShade: '#836AF9',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
}

export default {
  extends: Doughnut,
  setup() {
    const doughnutChart = {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        cutoutPercentage: 60,
        legend: { display: false },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const label = data.datasets[0].labels[tooltipItem.index] || ''
              const value = data.datasets[0].data[tooltipItem.index]
              const output = ` ${label} : ${value}`
              return output
            },
          },
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
      },
      data: {
        datasets: [
          {
            labels: [],
            data: [],
            backgroundColor: [chartColors.successColorShade, chartColors.warningLightColor, $themeColors.primary],
            borderWidth: 0,
            pointStyle: 'rectRounded',
          },
        ],
      },
    }

    return {
      doughnutChart,
    }
  },
  mounted() {
    this.renderChart(this.doughnutChart.data, this.doughnutChart.options)
  },
  methods: {
    updateChar(_labels, _data) {
      const chartData = {
        datasets: [
          {
            labels: _labels,
            data: _data,
            backgroundColor: [chartColors.successColorShade, chartColors.warningLightColor, $themeColors.primary],
            borderWidth: 0,
            pointStyle: 'rectRounded',
          },
        ],
      }
      this.renderChart(chartData, this.doughnutChart.options)
    },
  },
}
</script>
