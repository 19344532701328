<template>
  <b-card
    :title="title"
    :sub-title="subtitle"
  >
    <!-- chart -->
    <chartjs-component-doughnut-chart
      ref="doughnutChart"
      class="mb-3"
    />
    <!--/ chart -->

    <!-- stocks -->
    <div
      v-for="(detail,index) in detailData"
      :key="detail"
      :class="index < detailData.length-1 ? 'mb-1':''"
      class="d-flex justify-content-between"
    >
      <div class="d-flex align-items-center">
        <span class="font-weight-bold ml-75 mr-25">{{ detail }}</span>
        <span>- {{ detailValues[index] }}</span>
      </div>
    </div>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { BCard } from 'bootstrap-vue'
import ChartjsComponentDoughnutChart from './components/ChartjsComponentDoughnutChart.vue'
// import chartjsData from './chartjsData'

export default {
  components: {
    ChartjsComponentDoughnutChart,
    BCard,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      required: false,
      type: String,
      default: '',
    },
  },
  setup() {
    const detailData = ref([])
    const detailValues = ref([])

    return {
      detailData,
      detailValues,
    }
  },
  methods: {
    updateChar(labels, series) {
      this.$refs.doughnutChart.updateChar(labels, series)
      this.detailData = labels
      this.detailValues = series
    },
  },
}
</script>
