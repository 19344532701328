<template>
  <b-card no-body>
    <b-card-header>
      {{ $t('dashboards.auditoriaMolex.filters.dateRange') }}
    </b-card-header>
    <b-card-body>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-row>
          <b-col
            md="3"
          >
            <validation-provider
              #default="validationContext"
              name="payment-date-from"
              rules="required"
            >
              <b-form-group
                :label="$t('dashboards.auditoriaMolex.filters.paymentDateFrom')"
                label-for="payment-date-from"
              >
                <b-form-datepicker
                  id="payment-date-from"
                  v-model="searchParameteres.paymentDateFrom"
                  class="mb-1"
                  today-button
                  reset-button
                  close-button
                  :label-today-button="$t('AppDigitalRecord.calendar.labelTodayButton')"
                  :label-reset-button="$t('AppDigitalRecord.calendar.labelResetButton')"
                  :label-close-button="$t('AppDigitalRecord.calendar.labelCloseButton')"
                  :label-prev-year="$t('AppDigitalRecord.calendar.labelPrevYear')"
                  :label-prev-month="$t('AppDigitalRecord.calendar.labelPrevMonth')"
                  :label-current-month="$t('AppDigitalRecord.calendar.labelCurrentMonth')"
                  :label-next-month="$t('AppDigitalRecord.calendar.labelNextMonth')"
                  :label-next-year="$t('AppDigitalRecord.calendar.labelNextYear')"
                  :label-no-date-selected="$t('AppDigitalRecord.calendar.labelNoDateSelected')"
                  :label-help="$t('AppDigitalRecord.calendar.labelHelp')"
                  :placeholder="$t('AppDigitalRecord.noDateSelected')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="3"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('dashboards.auditoriaMolex.filters.paymentDateTo')"
              rules="required"
            >
              <b-form-group
                :label="$t('dashboards.auditoriaMolex.filters.paymentDateTo')"
                label-for="payment-date-to"
              >
                <b-form-datepicker
                  id="payment-date-to"
                  v-model="searchParameteres.paymentDateTo"
                  class="mb-1"
                  today-button
                  reset-button
                  close-button
                  :label-today-button="$t('AppDigitalRecord.calendar.labelTodayButton')"
                  :label-reset-button="$t('AppDigitalRecord.calendar.labelResetButton')"
                  :label-close-button="$t('AppDigitalRecord.calendar.labelCloseButton')"
                  :label-prev-year="$t('AppDigitalRecord.calendar.labelPrevYear')"
                  :label-prev-month="$t('AppDigitalRecord.calendar.labelPrevMonth')"
                  :label-current-month="$t('AppDigitalRecord.calendar.labelCurrentMonth')"
                  :label-next-month="$t('AppDigitalRecord.calendar.labelNextMonth')"
                  :label-next-year="$t('AppDigitalRecord.calendar.labelNextYear')"
                  :label-no-date-selected="$t('AppDigitalRecord.calendar.labelNoDateSelected')"
                  :label-help="$t('AppDigitalRecord.calendar.labelHelp')"
                  :placeholder="$t('AppDigitalRecord.noDateSelected')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="3"
          >
            <b-form-group
              label="Aduana"
              label-for="custom"
            >
              <v-select
                id="custom"
                v-model="searchParameteres.custom"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="texto"
                :options="customsOptions.map(m => m.texto)"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="3"
          >
            <b-form-group
              label="Cliente"
              label-for="customer"
            >
              <v-select
                id="customer"
                v-model="searchParameteres.customer"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="texto"
                :options="customersOptions"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="3"
          >
            <b-button
              variant="primary"
              block
              style="margin-top: 20px;"
              @click="handleSubmit(clickQuery)"
            >
              {{ $t('dashboards.auditoriaMolex.filters.query') }}
              <feather-icon
                icon="SearchIcon"
              />
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>
<script>
import { ref } from '@vue/composition-api'
import {
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormDatepicker,
  BButton,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required,
} from '@validations'
import optionService from '@/services/option.service'
import store from '@/store'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormDatepicker,
    BButton,
    BRow,
    BCol,
    BFormInvalidFeedback,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  setup(props, { emit }) {
    /* Services */
    const {
      fecthCustoms,
      fetchOptions,
    } = optionService()
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    const searchParameteres = ref({
      paymentDateFrom: store.state.auditoriaMolex.dateFrom,
      paymentDateTo: store.state.auditoriaMolex.dateTo,
      custom: store.state.auditoriaMolex.custom,
      customer: store.state.auditoriaMolex.customer,
    })

    const customsOptions = ref([])
    fecthCustoms(data => {
      customsOptions.value = data
    })

    const customersOptions = ref([])
    fetchOptions({ nombreDominio: 'Clientes' }, data => {
      customersOptions.value = data
    })

    const clickQuery = () => {
      store.dispatch('auditoriaMolex/updateParams', searchParameteres.value)
      emit('refetch-data')
    }

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      required,

      searchParameteres,
      customsOptions,
      customersOptions,
      clickQuery,
    }
  },
}
</script>
<style lang="scss" scoped>

</style>
