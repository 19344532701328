<template>
  <b-card
    no-body
  >
    <list
      ref="refEventsTable"
      sort-by="AuditoriaBitacoraEventoID"
      refetch-records-name="eventos"
      key-field="AuditoriaBitacoraEventoID"
      report-name="HallazgosAuditoria.xlsx"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fetchAuditoriaMolexEventos"
      :fetch-report="fetchAuditoriaMolexExportarExcelHallazgos"
      :upload-template="createAuditoriaMolexImportarExcelHallazgos"
      table-store="auditoriaMolex"
    >
      <!-- pedimento number -->
      <template #cell(numeroPedimento)="data">
        {{ data.item.numeroPedimento }}
      </template>
      <!-- Total -->
      <template #cell(fechaOperacion)="data">
        <!-- Format Date -->
        <b-media v-if="stateTime.value" vertical-align="center">
          {{ data.item.fechaOperacion | formatDateTime }}
        </b-media>
        <b-media v-else vertical-align="center">
          {{ data.item.fechaOperacion | formatDate }}
        </b-media>
      </template>
      <!-- Leyenda -->
      <template #cell(leyenda)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.leyenda }}</span>
        </div>
      </template>
      <!-- Nivel de informacion -->
      <template #cell(nivelInformacion)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.nivelInformacion }}</span>
        </div>
      </template>
      <!-- Nivel de informacion -->
      <template #cell(campo)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.campo }}</span>
        </div>
      </template>
      <!-- Estado de hallazgo  -->
      <template #cell(auditoriaEstadoEventoId)="data">
        <b-badge
          pill
          :variant="`light-${data.item.auditoriaEstadoEventoId === 1 ? 'danger' : 'success'}`"
          class="text-capitalize"
        >
          <span class="font-weight-bolder">{{ data.item.auditoriaEstadoEventoId === 1 ? 'ABIERTO' : 'CERRADO' }}</span>
        </b-badge>
      </template>
    </list>
  </b-card>
</template>
<script>
import i18n from '@/libs/i18n'
import {
  BCard,
  BBadge,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import dashboardService from '@/services/dashboard.service'
import List from '@/components/List.vue'
import store from '@/store'

export default {
  components: {
    BCard,
    BBadge,
    List,
  },
  setup() {
    /* Refs */
    const refEventsTable = ref(null)
    const dateTimeFormater = JSON.parse(localStorage.getItem('stateTime'))
    if (!dateTimeFormater.find(e => e.key === 'formatDateEvents')) {
      dateTimeFormater.push({ key: 'formatDateEvents', value: false })
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
    }
    const stateTime = ref(dateTimeFormater.find(e => e.key === 'formatDateEvents'))
    let actions = ref([])

    const changeStateTime = () => {
      stateTime.value.value = !stateTime.value.value
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
      const index = actions.value.findIndex(e => e.name === 'dateAndHour')
      actions.value[index].value = stateTime.value.value
    }
    /* Services */
    const {
      fetchAuditoriaMolexEventos,
      fetchAuditoriaMolexExportarExcelHallazgos,
      createAuditoriaMolexImportarExcelHallazgos,
    } = dashboardService()

    const filters = ref([
      {
        type: 'date',
        name: 'fechaDesde',
        label: i18n.t('dashboards.auditoriaMolex.filters.paymentDateFrom'),
        value: store.state.auditoriaMolex.dateFrom,
        disabled: true,
      },
      {
        type: 'date',
        name: 'fechaHasta',
        label: i18n.t('dashboards.auditoriaMolex.filters.paymentDateTo'),
        value: store.state.auditoriaMolex.dateTo,
        disabled: true,
      },
      {
        type: 'select',
        name: 'aduana',
        label: i18n.t('dashboards.auditoriaMolex.events.filterCustom'),
        value: null,
        options: [],
        domainName: 'Aduanas',
        disabled: true,
      },
      {
        type: 'select',
        name: 'clienteId',
        label: i18n.t('dashboards.auditoriaMolex.events.filterCustomer'),
        value: null,
        options: [],
        domainName: 'Clientes',
        disabled: true,
      },
      {
        type: 'select',
        name: 'filtroTipoHallazgo',
        label: i18n.t('dashboards.auditoriaMolex.events.filterFinding'),
        value: 0,
        options: [],
        domainName: 'AuditoriaGrupoEventos',
      },
      {
        type: 'select',
        name: 'auditoriaEstadoEventoId',
        label: i18n.t('dashboards.auditoriaMolex.events.filterStatus'),
        value: 0,
        options: [],
        domainName: 'AuditoriaEstadoEventos',
      },
    ])

    const tableColumns = [
      {
        key: 'numeroPedimento',
        label: i18n.t('dashboards.auditoriaMolex.events.request'),
        sortKey: 'NumeroPedimento',
        sortable: true,
      },
      {
        key: 'numeroReferencia',
        label: i18n.t('dashboards.auditoriaMolex.events.reference'),
        sortKey: 'NumeroReferencia',
        sortable: true,
      },
      {
        key: 'claveDocumento',
        label: i18n.t('dashboards.auditoriaMolex.events.documentKey'),
        sortKey: 'ClaveDocumento',
        sortable: false,
      },
      {
        key: 'fechaOperacion',
        label: i18n.t('dashboards.auditoriaMolex.events.operationDate'),
        sortKey: 'FechaOperacion',
        sortable: true,
      },
      { key: 'leyenda', label: i18n.t('dashboards.auditoriaMolex.events.comment') },
      { key: 'nivelInformacion', label: i18n.t('dashboards.auditoriaMolex.events.level') },
      { key: 'campo', label: i18n.t('dashboards.auditoriaMolex.events.field') },
      { key: 'auditoriaEstadoEventoId', label: i18n.t('dashboards.auditoriaMolex.events.status') },
      { key: 'seguimientoObservaciones', label: i18n.t('dashboards.auditoriaMolex.events.observations') },
      { key: 'actions', label: i18n.t('Lists.Actions') },
    ]

    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'write',
        aclResource: 'AuditoriaMolex',
        routeName: 'auditoria-molex-details',
        params: ['auditoriaBitacoraEventoID'],
        icon: 'EditIcon',
      },
      {
        name: 'download',
        label: i18n.t('Lists.Download'),
        aclAction: 'read',
        aclResource: 'AuditoriaMolex',
        click: item => window.open(`${process.env.VUE_APP_ADUACORE_API_URL}/api/Archivos/ExpedienteDigital/${item.guidArchivoPedimentoBorrador}`, '_blank'),
        disabledCondition: item => !item.guidArchivoPedimentoBorrador,
        icon: 'DownloadCloudIcon',
      },
    ])

    actions = ref([
      {
        name: 'downloadReport',
        aclAction: 'read',
        aclResource: 'AuditoriaMolex',
      },
      {
        name: 'uploadTemplate',
        aclAction: 'write',
        aclResource: 'AuditoriaMolex',
      },
      {
        name: 'dateAndHour',
        aclAction: 'read',
        aclResource: 'AccionesMejora',
        icon: 'CalendarIcon',
        value: stateTime.value.value,
        click: changeStateTime,
      },
    ])
    const refetchData = () => {
      filters.value[0].value = store.state.auditoriaMolex.dateFrom
      filters.value[1].value = store.state.auditoriaMolex.dateTo
      filters.value[2].value = store.state.auditoriaMolex.custom
      filters.value[3].value = store.state.auditoriaMolex.customer
      refEventsTable.value.refreshData(store.state.auditoriaMolex.customer)
    }

    return {
      refEventsTable,

      filters,
      tableColumns,
      tableActions,
      actions,
      stateTime,

      refetchData,
      fetchAuditoriaMolexEventos,
      fetchAuditoriaMolexExportarExcelHallazgos,
      createAuditoriaMolexImportarExcelHallazgos,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
